import React from 'react';
import {Body} from './components/Body/body';


function App() {
  return (
    <div className="App">
      <Body/>
    </div>
  );
}

export default App;
